/* eslint-disable import/no-anonymous-default-export */
import getcat from "../../utils/cat"
import get from "../../utils/spotify"
import getcom from "../../utils/comando"

export default {
   
    
    commands : {
     

        echo: {
            description: 'Prints the given text to the console',
            usage: 'echo <text>',
            fn: (...args) => args.join(" ")
        },
      


    },
    overwrites: {

        help: {
            description: 'List all available commands',
            usage: 'help',
        },
        cd: {
            description: 'Change directory, not really, lol!',
            usage: 'cd <directory>',
        },
        ls: {
            description: 'List files in the current directory',
            usage: 'ls',
        },
        mkdir: {
            description: 'Make a directory',
            usage: 'mkdir <directory>',
        },
        ifconfig: {
            description: 'Get TCP/IP network configuration data',
            usage: 'ifconfig',
        },
        clear: {
            description: 'Clears the terminal',
            usage: 'clear'
        },
        cat: {
            description: 'Get a cute cat image.',
            usage: 'cat',
        },
        usufy:{
            description: ' usufy - Piece of software that checks the existence of a profile for a given user in dozens of different platforms',
            usage: 'usufy -n <username>',
        },mailfy: {
            description: 'mailfy - Checking the existence of a given mail.',
            usage: 'mailfy -m <email>',
        }
    }
}

        
